import React, { createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAsync, logoutUserAsync } from "../store/thunks/authThunk";

// Create the AuthContext
const AuthContext = createContext();

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.activeUser?.isAuthenticated ?? false);
    const dispatch = useDispatch();

    const handleUserLoginAsync = async (userData) => {
        try {
            await dispatch(loginUserAsync(userData));
        } catch (error) {
            console.error("Error logging in:", error);
            throw error;
        }
    };

    const handleUserLogout = () => dispatch(logoutUserAsync());

    return (
        <AuthContext.Provider
            value={{ isAuthenticated, handleUserLoginAsync, handleUserLogout }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to access the AuthContext
export const useAuth = () => useContext(AuthContext);
