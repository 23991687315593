import { useState } from "react";

import UserBanner from "../../applicationUi/components/UserBanner";

import WideSidePanel from "../../applicationUi/components/WideSidePanel";
import StepsVerticalList from "../../applicationUi/components/StepsVerticalList";
import TwoColumnDescriptionList from "../../applicationUi/components/TwoColumnDescriptionList";
import ClinicianDashboardComponent from "./ClinicianDashboardComponent";
import KPIComponent from "./components/kpiComponent";


const user = [{}];

function DashboardPage() {
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isWideSidePanelOpen, setWideSidePanelOpen] = useState(false);

    const handleAppointmentClick = (appointment) => {
        setSelectedAppointment(appointment);
        setWideSidePanelOpen(true);
    };

    const handleClientClick = (client) => {
        setSelectedClient(client);
        setWideSidePanelOpen(true);
    };

    const handleCloseWideSidePanel = () => {
        setWideSidePanelOpen(false); // Close the WideSidePanel
    };

    return (
        <>
            <UserBanner user={user} />
            <KPIComponent />

            {selectedAppointment && (
                <WideSidePanel
                    title={"Appointment Summary"}
                    open={isWideSidePanelOpen}
                    onClose={handleCloseWideSidePanel}
                >
                    <TwoColumnDescriptionList
                        appointment={selectedAppointment}
                    />
                    <StepsVerticalList />
                </WideSidePanel>
            )}
            {selectedClient && (
                <WideSidePanel
                    title={"Client Informaton"}
                    open={isWideSidePanelOpen}
                    onClose={handleCloseWideSidePanel}
                >
                    <TwoColumnDescriptionList />
                </WideSidePanel>
            )}
        </>
    );
}

export default DashboardPage;
