// authThunks.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";

// Async thunk to login a user
const loginUserAsync = createAsyncThunk("auth/login", async (userData) => {
    try {
        const { baseURL, config } = generateConfig();
        const response = await axios.post(`${baseURL}/login`, userData, config);
        localStorage.setItem("token", response.data.token);
        return response.data.user;
    } catch (error) {
        console.error("Error logging in:", error);
        throw error;
    }
});

// Async thunk to logout a user
const logoutUserAsync = createAsyncThunk(
    "auth/logout",
    async (_, { dispatch }) => {
        try {
            // Perform any logout logic, such as invalidating tokens or clearing local storage
            // For example:
            // await axios.post(`${baseURL}/logout`);
        } catch (error) {
            // Handle logout error
            console.error("Error logging out:", error);
            throw error;
        }
    }
);

export { loginUserAsync, logoutUserAsync };
