import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import "./index.css";
import { AuthProvider } from "./auth/authContext";
import AppRouter from "./appRouter";

const root = createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AuthProvider>
                    <BrowserRouter>
                        <AppRouter />
                    </BrowserRouter>
                </AuthProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
