import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./auth/authContext";
import App from "./App";
import Login from "./auth/login";
import { generateConfig } from "./utils/api";

export default function AppRouter() {
    const { isAuthenticated } = useAuth();
    const { baseURL, config } = generateConfig();
    console.log("BaseURL :" + baseURL);
  
    return (
        <Routes>
            {/* Redirect to login if not authenticated */}
            <Route path="/dashboard" element={<App />} />
            <Route
                path="/"
                element={isAuthenticated ? <App /> : <Login />}
            />
            <Route path="/login" element={<Login />} />
        </Routes>
    );
}
