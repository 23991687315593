import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthSliceReducer from "./slices/authSlice";
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "rtcrm",
  storage,
};

const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    activeUser: AuthSliceReducer
  })
);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./thunks/authThunk";
