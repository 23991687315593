import React from 'react';

const KPIComponent = ({ totalLeadsCount, leadChangeStatus, leadChangePercentage, netChangesLeadsCount }) => {
  const handleClick = () => {
    // Add your logic for handling click event
    console.log('KPI clicked');
  };

  return (
    <div className="col-xl-4 flex-kpi" onClick={handleClick}>
      <div className="counter">
        <h3 className="trip-completed">Leads</h3>
        <span className="counter-value">{totalLeadsCount}</span>
      </div>
      <div className="flex-1">
        <div className="value-flex">
          <div>
            {leadChangeStatus === 'positive' ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.242"
                  height="19.243"
                  viewBox="0 0 19.242 19.243"
                  className="text-green-500"
                >
                  <g id="Icon_feather-arrow-up-right" transform="translate(-8.379 -8.379)">
                    <path
                      id="Path_458"
                      d="M10.5,25.5l15-15"
                      fill="none"
                      stroke="#00aa34"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    />
                    <path
                      id="Path_459"
                      d="M10.5,10.5h15v15"
                      fill="none"
                      stroke="#00aa34"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    />
                  </g>
                </svg>
                <label>{leadChangePercentage}% </label>
              </>
            ) : leadChangeStatus === 'negative' ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.242"
                  height="19.243"
                  viewBox="0 0 19.242 19.243"
                  className="text-red-500"
                >
                  <g id="Icon_feather-arrow-up-right" transform="translate(27.621 -8.379) rotate(90)">
                    <path
                      id="Path_458"
                      d="M10.5,25.5l15-15"
                      fill="none"
                      stroke="red"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    />
                    <path
                      id="Path_459"
                      d="M10.5,10.5h15v15"
                      fill="none"
                      stroke="red"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    />
                  </g>
                </svg>
                <label>{leadChangePercentage}% </label>
              </>
            ) : (
              '-'
            )}
          </div>
          <p>Change</p>
        </div>
        <div className="value-flex mt-custom">
          <label>{netChangesLeadsCount}</label>
          <p>Prev.30d</p>
        </div>
      </div>
    </div>
  );
};

export default KPIComponent;
