    // this is a combination of usersSlice and editActiveUsersSlice

    import { createSlice } from "@reduxjs/toolkit";
    import {
        loginUserAsync, 
        logoutUserAsync
    } from "../thunks/authThunk";

    const authSlice = createSlice({
    name: "activeUser",
    initialState: {
        user: null,
        isAuthenticated: false,
        status: "idle",
        isLoading: false,
        error: null,
    },
    reducers: {
        setActiveUser: (state, action) => {
        state.activeUser = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        // loginUserAsynce thunks
        .addCase(loginUserAsync.pending, (state) => {
            state.status = "loading";
        })
        .addCase(loginUserAsync.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.isAuthenticated = true;
            state.user = action.payload;
        })
        .addCase(loginUserAsync.rejected, (state, action) => {
            state.status = "failed";
            state.isAuthenticated = false;
            state.error = action.error.message;
        })
        // logoutUserAsync thunks
        .addCase(logoutUserAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(logoutUserAsync.fulfilled, (state, action) => {
            state.isAuthenticated = false
            state.user = action.payload;
            state.isLoading = false;
        })
        .addCase(logoutUserAsync.rejected, (state, action) => {
            state.error = action.error.message;
            state.isLoading = false;
        })
    },
    });

    export const { setActiveUser } = authSlice.actions;
    export default authSlice.reducer;
