export const generateConfig = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-api-token": process.env.REACT_APP_TOKEN,
      "x-api-secret": process.env.REACT_APP_SECRET,
      // Add other headers as needed
    },
  };

  const baseURL = process.env.REACT_APP_BASE_URL;
  console.log("Base URL:", baseURL); // Add this console.log statement

  if (!baseURL) {
    throw new Error("REACT_APP_BASE_URL is not defined in .env file");
  }

  return { config, baseURL };
};
